import React, { useEffect, useState } from "react";
import { Card as MaterialCard, Container, Row, Table } from "react-materialize";
import { Button, Empty, Input, Select, Spin, Rate, DatePicker } from "antd";
import TopHeader from "../TopHeader";
import styles from "./rating.module.scss";
import "./rating.scss";
import { getCardsBasedOnTitle, getUpdatedRatingCards, saveNewQAOnCard } from "../../services/cardsService";
import { disableColor, errorToast, getUserShortName, infoToast, isMobile, primaryColor, secondaryColor, successToast, toISODate } from "../../api/Util";
import { useSelector } from "react-redux";
import Ratings from "react-ratings-declarative";
import { debounce } from 'lodash';
import Pagination from "react-js-pagination";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import CustomDatePicker from "../common/DatePicker";
import { QA_USER } from "../../api/Constants";

const INITIAL_OPTIONS = [
	{
		value: "done",
		label: "Done",
	},
	{
		value: "repurpose",
		label: "Repurpose Done",
	},
]

function Rating() {
	const [cardsData, setCardsData] = useState([]);
	const [selectedCard, setSelectedCard] = useState({});
	const [ratingSection, setRatingSection] = useState();
	const [selectedOldQA, setSelectedOldQA] = useState();
	const [selectedNewQA, setSelectedNewQA] = useState();
	const [options, setOptions] = useState([]);
	const [searchQuery, setSearchQuery] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [rating, setRating] = useState(0);
	const [ratingReason, setRatingReason] = useState();
	const [index, setIndex] = useState(1);
	const [updatedRatingCardsData, setUpdatedRatingCardsData] = useState([]);
	const [totalCardsCount, setTotalCardsCount] = useState([]);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [addDateRangeModalOpen, setAddDateRangeModalOpen] = useState(false);
	const [selectedRating, setSelectedRating] = useState(undefined);

	const state = useSelector((state) => state)
	const websiteUsers = useSelector(state => state.allWebsiteUsers.website_users);
	const limit = 10;

	const getCardsByTitle = async () => {
		const res = await getCardsBasedOnTitle(searchQuery);
		if (res.status) {
			setIsLoading(false);
			const temp = res.data?.map((item, i) => {
				return {
					cardId: item.card_id,
					cardTitle: item.card_title,
					qaAssignedTo: item.qa_assigned_to,
					cardRatingWithQA: item.card_rating?.map((data, j) => (
						{
							label: `${data?.card_rating_q_a?.firstname || ""} ${data.card_rating_q_a?.lastname || ""}`,
							value: data?.rating,
							key: data.card_type,
						}
					)),
					defaultSections: item.repurpose_aspect ? INITIAL_OPTIONS : [
						{
							value: "done",
							label: "Done",
						},
					]
				}
			})
			setCardsData(temp);
		} else {
			errorToast("Something went wrong");
		}
	}

	useEffect(() => {
		if (searchQuery !== null && searchQuery !== undefined && searchQuery !== "") {
			setIsLoading(true);
			debounced();
		} else {
			document.getElementById("autoSuggestionDiv").style.display = "none";
			setCardsData([]);
			setSelectedOldQA(null);
			setSelectedNewQA(null);
			setSelectedCard(null);
			setRatingSection(null);
			setRating(0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQuery]);

	const debounced = debounce(() => {
		getCardsByTitle();
	}, 1000);

	useEffect(() => {
		setSearchQuery(selectedCard?.cardTitle);
		const temp = selectedCard?.cardRatingWithQA?.find((item, i) => ratingSection === item.key);
		setSelectedOldQA(temp)
		setRating(temp?.value);
		setOptions(selectedCard?.defaultSections);
	}, [selectedCard, ratingSection])

	const submitNewQA = async () => {
		const obj = {
			cardId: selectedCard.cardId,
			newQA: selectedNewQA,
			cardType: ratingSection,
			rating: rating || selectedOldQA.value,
			ratingReason: ratingReason,
		}

		const res = await saveNewQAOnCard(obj);
		if (res.status) {
			successToast(res.data.success);
			setSelectedOldQA(null);
			setSelectedNewQA(null);
			setSelectedCard(null);
			setSearchQuery(null);
			setRatingSection(null);
			setOptions([]);
			setRatingReason();
			getRatedCards();
		} else {
			errorToast(res?.data?.error_message || "Error Occured");
		}
	}

	const getRatedCards = async () => {
		infoToast("Loading");
		let obj = {
			startDate: toISODate(startDate),
			endDate: toISODate(endDate),
			limit,
			index,
			selectedRating,
		}
		try {
			const res = await getUpdatedRatingCards(obj);
			if (res?.status) {
				setUpdatedRatingCardsData(res?.data?.updatedRatedCards);
				setTotalCardsCount(res?.data?.totalRatedCards);
			} else {
				errorToast("Something went wrong");
			}
		} catch (error) {
			errorToast("Error fetching data");
		}
	};

	useEffect(() => {
		if (startDate && endDate) {
			getRatedCards();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [index, selectedRating, startDate, endDate])

	const newOpt = [];
	for (let i = 1; i <= 5; i++) {
		newOpt.push({
			label: (
				<>
					<span style={{ display: 'inline-block', width: '20px' }}>{i}</span>
					<Rate value={i} style={{ marginLeft: '8px' }} disabled />
				</>
			),
			value: i.toString(),
		});
	}

	return (
		<Container id="video_upload">
			<div className="aside-right-top-bar">
				<div className="top-bar-left">
					<div className="top_bar">
						<h3>Update QA Rating</h3>
					</div>
				</div>
				<div className="top-bar-right">
					<TopHeader />
				</div>
			</div>

			<div className={styles.container}>
				<div className={styles.innerContainer}>
					<div className={styles.divWrapper}>
						<p className={styles.inputLabel}>Search Card</p>
						<div className={styles.autoCompleteInputWrapper}>
							<Input.Search
								size={isMobile ? "medium" : "large"}
								placeholder="Search Cards"
								suffix={<Spin spinning={isLoading} size="small" />}
								value={searchQuery}
								className={`${styles.searchInput} searchInputStyle`}
								onChange={(e) => { document.getElementById("autoSuggestionDiv").style.display = "block"; setSearchQuery(e.target.value) }}
							/>
							<div className={styles.customInputDiv} id="autoSuggestionDiv">
								{cardsData?.map((item, i) => {
									return <p className={styles.optionListItem} onClick={() => { setRatingSection("done"); setSelectedCard(item); document.getElementById("autoSuggestionDiv").style.display = "none" }}>{item.cardTitle}</p>
								})}
								{
									cardsData?.length === 0 && <Empty className={styles.emptyDataElement} />
								}
							</div>
						</div>
					</div>
					<div className={styles.divWrapper}>
						<p className={styles.inputLabel}>Rating For</p>
						<Select
							size={isMobile ? "medium" : "large"}
							className={`${styles.viewAsStyle} selectCustomStyle`}
							showSearch
							optionFilterProp="children"
							value={ratingSection}
							onChange={(value) => setRatingSection(value)}
							options={options}
							placeholder="Select Section"
						/>
					</div>
				</div>

				<div className={styles.innerContainer}>
					<div className={styles.divWrapper}>
						<p className={styles.inputLabel}>Old Assigned QA</p>
						<Select
							size={isMobile ? "medium" : "large"}
							className={`${styles.viewAsStyle} disableSelectBox selectCustomStyle`}
							value={selectedOldQA}
							placeholder="Old QA"
							disabled
						/>
					</div>
					<div className={styles.divWrapper}>
						<p className={styles.inputLabel}>New Assinged QA</p>
						<Select
							size={isMobile ? "medium" : "large"}
							className={`${styles.viewAsStyle} selectCustomStyle`}
							showSearch
							optionFilterProp="children"
							value={selectedNewQA}
							filterOption={(input, option) => (option?.label ?? '').includes(input)}
							onChange={(value) => setSelectedNewQA(value)}
							options={
								(websiteUsers || [])
									.filter(user => user.client_type === QA_USER)
									.map(user => ({
										value: user.client_id,
										label: `${getUserShortName(user.fullname)} (${user.client_type})`
									}))
							}
							placeholder="New QA"
						/>
					</div>
				</div>

				<div className={styles.innerContainer}>
					<div className={styles.divWrapper}>
						<p className={styles.inputLabel}>Reason</p>
						<div>
							<Input
								size={isMobile ? "medium" : "large"}
								placeholder="Rating Reason"
								suffix={<Spin spinning={isLoading} size="small" />}
								value={ratingReason}
								className={`${styles.searchInput} searchInputStyle`}
								onChange={(e) => setRatingReason(e.target.value)}
							/>
						</div>
					</div>
				</div>

				<div className={styles.innerContainer}>
					<div className={styles.divWrapper}>
						<p className={styles.inputLabel}>Rating</p>
						<Ratings
							rating={rating || selectedOldQA?.value}
							widgetRatedColors={primaryColor}
							changeRating={setRating}
							widgetEmptyColors={disableColor}
							widgetDimensions="40px"
							widgetHoverColors={primaryColor}
						>
							<Ratings.Widget />
							<Ratings.Widget />
							<Ratings.Widget />
							<Ratings.Widget />
							<Ratings.Widget />
						</Ratings>
					</div>
				</div>

				<div className={styles.innerContainer}>
					<div className={styles.btnWrapper}>
						<Button
							className={`standard-btn ${styles.submitButton}`}
							type="primary"
							disabled={!(ratingSection && ((selectedOldQA?.value !== rating && ratingReason && ratingReason.length > 5)
								|| (selectedNewQA && ratingReason && ratingReason.length > 5)))}
							onClick={() => submitNewQA()}>
							Submit
						</Button>
					</div>
				</div>
			</div>

			<MaterialCard className={`${styles.materialCard} ${styles.datePickerAndSelectWrapper}`}>
				<CustomDatePicker className={`${styles.datePicker} ${styles.mobileMode}`} setStartDate={setStartDate} setEndDate={setEndDate} startDate={startDate} endDate={endDate} />
				<Select
					className={`${styles.selectBox} selectBoxStyle`}
					allowClear
					placeholder="Please select rating"
					onChange={(value) => setSelectedRating(value)}
					value={selectedRating}
					options={newOpt}
				/>
			</MaterialCard>

			<MaterialCard className={styles.materialCard}>
				<Table className={styles.ratingCardsTable}>
					<thead>
						<tr>
							<th className="align-center">SR. Number</th>
							<th className="align-center">Card Title</th>
							<th className="align-center">Rating</th>
							<th className="align-center">QA Name</th>
							<th className="align-center">Reason</th>
							<th className="align-center">Created Date</th>
						</tr>
					</thead>
					<tbody>
						{updatedRatingCardsData && updatedRatingCardsData.length > 0 ? (
							updatedRatingCardsData.map((item, i) => (
								<tr key={'rating-card-table-row' + i}>
									<td className="align-center" width="10%">{(index - 1) * limit + i + 1}</td>
									<td className="align-center" width="30%">{item.card_title}</td>
									<td className="align-center" width="10%">{item.rating}</td>
									<td className="align-center" width="10%">{item.qaFirstname} {item.qaLastname}</td>
									<td className="align-center" width="30%">{item.reason}</td>
									<td className="align-center" width="10%">{item.created_at}</td>
								</tr>
							))
						) : (
							<tr>
								<td className="align-center" colSpan="6">No data found</td>
							</tr>
						)}
					</tbody>

				</Table>
				{
					totalCardsCount > 0 ? <Row className="center-align">
						<Pagination
							activePage={index}
							itemsCountPerPage={10}
							totalItemsCount={totalCardsCount}
							pageRangeDisplayed={10}
							onChange={i => { setIndex(i) }}
							prevPageText={<AiOutlineLeft />}
							nextPageText={<AiOutlineRight />}
							firstPageText={<AiOutlineDoubleLeft />}
							lastPageText={<AiOutlineDoubleRight />}
						/>
					</Row> : null
				}
			</MaterialCard>
		</Container>
	);
}

export default Rating;
