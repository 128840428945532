import React, { useEffect, useState } from 'react';
import { Layout, Space, Input, Button, DatePicker, Select, Tooltip } from 'antd';
import * as _ from 'lodash';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getUserShortName, isAdmin, isCustomer, isMobile, isTeamLead } from "../../../api/Util";
import styles from './TabHeadingStyle.module.scss';
import './TabHeadingStyle.scss';
import { CUSTOMER, QA_USER, YT_HELPER, YT_HELPER_LEAD } from '../../../api/Constants';
import * as actions from "../../../actions/index";
import TimeClock from '../../common/TimeClock/TimeClock';
import dayjs from 'dayjs';
const ListViewHeader = ({activeTab, setActiveTab, cardData, setCardData, setTotalCards, filterCards, setFilterCards, isLoading, setIsLoading, offset, setOffset, queryParamsValue }) => {
    const [viewAs, setViewAsState] = useState(null);
    const dispatch = useDispatch();
    const { Header } = Layout;
    const props = useSelector((state) => state)
    const loggedInUser = props.auth?.loggedInUser;
    const admin = isAdmin(loggedInUser);
    const customer = isCustomer(loggedInUser);
    const websiteUsers = useSelector(state => state.allWebsiteUsers.website_users);
    const teamLead =isTeamLead(loggedInUser);
    const requestsLimited = customer && loggedInUser.request_limit;
    const accountPending = customer && loggedInUser.qas?.length <= 0;
    const viewType = localStorage.getItem("viewType");
    const timezone = process.env.REACT_APP_TIMEZONE;
    const [userEnteredDate, setUserEnteredDate] = useState(false);
    const [prevDates, setPrevDates] = useState({
        startDate:  queryParamsValue?.queryStartDate ? dayjs(queryParamsValue.queryStartDate) : null,
        endDate: queryParamsValue?.queryEndDate ? dayjs(queryParamsValue.queryEndDate) : null,
    });
    const [displayDates, setDisplayDates] = useState([
        queryParamsValue?.queryStartDate ? dayjs(queryParamsValue.queryStartDate) : '',
        queryParamsValue?.queryEndDate ? dayjs(queryParamsValue.queryEndDate) : '',
    ]);

    function setViewAs(v) {
        localStorage.setItem("listViewAs",v)
        setCardData([]);
        setTotalCards(0);
        setIsLoading(true);
        setOffset(0);
        setFilterCards({ ...filterCards, view_as: v });
    }

    const makeActiveTab = (tab) => {
        setActiveTab(tab);
        setViewAsState(null);
        localStorage.setItem('activeTab', tab);
        localStorage.setItem('adminActiveTab', tab);
        setCardData([]);
        setTotalCards(0);
        setFilterCards({ ...filterCards, card_name: undefined })
    }
    const handleDateRange = (dates) => {
        if (dates) {
          setFilterCards({
            ...filterCards,
            startDate: dates[0],
            endDate: dates[1],
          });
          setDisplayDates(dates);
          setUserEnteredDate(true);
        }
      };
    
      const onOpenChange = (open) => {
        if (open) {
          if (userEnteredDate) {
            setPrevDates({
              startDate: filterCards.startDate,
              endDate: filterCards.endDate,
            });
            // setDisplayDates([null, null]);
          }  
        //    if (queryParamsValue.queryStartDate && queryParamsValue.queryEndDate) {
        //       setDisplayDates([null, null]);
        //    }
        } else {
            if (displayDates[0] === null && displayDates[1] === null) {
            setDisplayDates([prevDates.startDate, prevDates.endDate]);
          }
        }
      };

    useEffect(() => {
        if(_.isEmpty(props.settings)) {
            dispatch(actions.fetchSettings());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewAs, admin])

    useEffect(() => {
        if (isMobile) {
            let elem = document.getElementById("active_"+activeTab);
            // eslint-disable-next-line no-unused-expressions
            elem?.scrollIntoView();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const clearFilter = () => {
        setFilterCards({
          card_name: undefined,
          startDate: "",
          endDate: "",
          view_as: null,
        });
        localStorage.removeItem("listViewAs");
        localStorage.removeItem("listSearchText");
        clearQueryParams();
        setActiveTab("all_tabs")
        setViewAsState(null);
        setPrevDates([null, null])
        setDisplayDates([null, null])
      };

      const clearQueryParams = () => {
        const newUrl = window.location.pathname;  
        window.history.replaceState({}, '', newUrl);
    };
      
  const activeFilterCount = () => {
    let count = 0;
    if (filterCards?.startDate && filterCards?.endDate) count++;
    if (filterCards?.view_as) count++;
    if (filterCards?.card_name) count++;
    return count;
};

    return (
        <Header className={styles.header}>
            <ul className={styles.ulTabs}>
                <li id={"active_all_tabs"} className={activeTab === "all_tabs" ? styles.activeTab : ""} onClick={() => makeActiveTab("all_tabs")}>All Tabs</li>
                {
                    viewType === "normal" && <>
                            <li id={"active_video_request"} className={activeTab === "video_request" ? styles.activeTab : ""} onClick={() => makeActiveTab("video_request")}>Video Requests</li>
                            <li id={"active_on_hold"} className={activeTab === "on_hold" ? styles.activeTab : ""} onClick={() => makeActiveTab("on_hold")}>On Hold</li>
                            <li id={"active_editing"} className={activeTab === "editing" ? styles.activeTab : ""} onClick={() => makeActiveTab("editing")}>Editing</li>
                            <li id={"active_qa"} className={activeTab === "qa" ? styles.activeTab : ""} onClick={() => makeActiveTab("qa")}>QA</li>
                            <li id={"active_done"} className={activeTab === "done" ? styles.activeTab : ""} onClick={() => makeActiveTab("done")}>Review</li>
                            <li id={"active_canceled"} className={activeTab === "canceled" ? styles.activeTab : ""} onClick={() => makeActiveTab("canceled")}>Canceled</li>
                            <li id={"active_repurpose_editing"} className={activeTab === "repurpose_editing" ? styles.activeTab : ""} onClick={() => makeActiveTab("repurpose_editing")}>Repurposing</li>
                            <li id={"active_repurpose_done"} className={activeTab === "repurpose_done" ? styles.activeTab : ""} onClick={() => makeActiveTab("repurpose_done")}>Repurpose Review</li>
                            <li id={"completed_done"} className={activeTab === "completed_done" ? styles.activeTab : ""} onClick={() => makeActiveTab("completed_done")}>Completed</li>
                        </>
                }
                {
                    viewType === "yt-helper" && <>
                        <li id={"active_assigned"} className={activeTab === "assigned" ? styles.activeTab : ""} onClick={() => makeActiveTab("assigned")}>Assigned</li>
                        <li id={"active_inProgress"} className={activeTab === "inProgress" ? styles.activeTab : ""} onClick={() => makeActiveTab("inProgress")}>In Progress</li>
                        <li id={"active_qa"} className={activeTab === "ytQA" ? styles.activeTab : ""} onClick={() => makeActiveTab("ytQA")}>QA</li>
                        <li id={"active_inDone"} className={activeTab === "inDone" ? styles.activeTab : ""} onClick={() => makeActiveTab("inDone")}>Review</li>
                        <li id={"active_cancelled"} className={activeTab === "cancelled" ? styles.activeTab : ""} onClick={() => makeActiveTab("cancelled")}>Cancelled</li>
                        <li id={"active_completed"} className={activeTab === "completed" ? styles.activeTab : ""} onClick={() => makeActiveTab("completed")}>Completed</li>
                            </>
                }
            </ul>
            <div className={`${styles.rightSection} rightSectionStyle`}>
                <Space direction="horizontal"  className={`${styles.spaceWrapper} spaceStyle ${isMobile && viewType === "yt-helper" ? "space-wrapper-yt-helperView" : ""}`}>
                    {
                        !isMobile && <TimeClock
                                        timezone={timezone}
                                        clockDivStyle={styles.timezoneDiv}
                                        format={"DD-MMM-YYYY hh:mm:ss z"}
                                        view={"listView"}
                                        />
                    }
                    {viewType === "normal" && <DatePicker.RangePicker allowClear className={`${styles.datePicker} datepicker-list-view`}   onChange={handleDateRange} onOpenChange={onOpenChange} value={displayDates}/>}
                    {
                        (admin || isTeamLead(loggedInUser)) && viewType === "normal" &&
                            <Select
																className={`${styles.viewAsStyle} list-viewAs-dropdown`}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => 
                                    (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}     
                                value={websiteUsers ? filterCards.view_as : ''}
                                onChange={setViewAs}
                                options={_.concat([{...loggedInUser, fullname: 'Me'}], 
                                websiteUsers.filter(user => user.client_type === QA_USER || user.client_type === YT_HELPER ) || [],websiteUsers || [])
                                .map(user => ({value: user.client_id, label: user.client_type === 'customer' ? `${user.fullname} (${user.client_type})` : `${getUserShortName(user.fullname)} (${user.client_type})`}))
                                    
                                }
                                placeholder="View As"
                            />

                    }
                    {
                        (admin || isTeamLead(loggedInUser)) && viewType === "yt-helper" &&
                            <Select
                                className={`${styles.viewAsStyle} list-viewAs-dropdown`}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => 
                                    (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}    
                                value={websiteUsers ? filterCards.view_as : ''}
                                onChange={setViewAs}
                                options={_.concat([{...loggedInUser, fullname: 'Me'}], 
                                websiteUsers.filter(user => user.client_type === YT_HELPER ) || [], websiteUsers || [])
                                    .filter((user) =>  user.fullname === 'Me'||user.client_type === YT_HELPER || user.client_type === YT_HELPER_LEAD || (user.client_type === CUSTOMER && user.has_youtube_helper ))
                                    .map(user => ({value: user.client_id, label: user.client_type === 'customer' ? `${user.fullname} (${user.client_type})` : `${getUserShortName(user.fullname)} (${user.client_type})`}))}
                                placeholder="View As"
                            />
                    }
                    {
                        !isMobile && <Input.Search
                        placeholder="Search Cards"
                        allowClear
                        value={filterCards.card_name}
                        className={`${styles.searchInput} searchInputStyle`}
                        onSearch={(value) => {localStorage.setItem("listSearchText", value); setFilterCards({ card_name: value });}}
                    />
                    }
                     <Tooltip title={"Clear Filter"} placement="bottom">
                    <Button type="primary" icon={<ReloadOutlined />} className={styles.createCardBtn} onClick={clearFilter}>{activeFilterCount() > 0 && `(+${activeFilterCount()})`}</Button>
                    </Tooltip>
                    {viewType === "normal" && !(requestsLimited || accountPending)
                        ? 
                        <Link to={{ pathname: `/new-card` }}>
                            {
                                (admin || customer) &&
                                    <Tooltip title={"Create New Video Request"} placement="bottom">
                                        <Button type="primary" icon={<PlusOutlined />} className={styles.createCardBtn} />
                                    </Tooltip>
                            }
                        </Link>
                        :
                        viewType === "normal" && <Link to={{ pathname: `/new-card` }} onClick={(e)=> e.preventDefault()}>
                            {
                                (admin || customer) &&
                                    <Tooltip title={requestsLimited ? `You have reached limit for video requests. Your limit will refresh on the next billing date. For further information contact your account manager.`
                                        : accountPending ? "We are setting up your account for file sharing, we will update you via email once it is done."
                                        : "Create New Video Request"} placement="bottom">
                                        <Button type="primary" icon={<PlusOutlined />} className={styles.createCardBtn} />
                                    </Tooltip>
                            }
                        </Link>
                    }
                </Space>
                {
                    isMobile && <Input.Search
                        placeholder="Search Cards"
                        allowClear
                        value={filterCards.card_name}
                        className={`${styles.searchInput} searchInputStyle ${viewType === "yt-helper" ? "space-wrapper-yt-helperView-searchInput" : ""}`}
                        onSearch={(value) => { localStorage.setItem("listSearchText", value); setFilterCards({ card_name: value }); }}
                    />
                }
                {
                    isMobile && <TimeClock
                                    timezone={timezone}
                                    clockDivStyle={styles.timezoneDiv}
                                    format={"DD-MMM-YYYY hh:mm:ss z"}
                                    view={"listView"}
                                    style={{ marginTop: viewType === "yt-helper" ? "8px" : 0 }}
                                    />
                }
            </div>
        </Header>
    );
}

export default ListViewHeader;
