import axios from "axios";

const baseUrl = process.env.REACT_APP_AI_BACKEND_URL;

export const generateAIResponse = async (obj) => {
  try {
    const data = await axios.get(`${baseUrl}api/ai/toolbox-response`, {
      params: obj,
    });
    return {
      status: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      status: false,
      error: "Some Error Occurred",
    };
  }
};

export const saveAIResponse = async (obj) => {
  try {
    const data = await axios.post(`${baseUrl}api/ai/save/toolbox-data`,obj);
    return {
      status: true,
    };
  } catch (error) {
    return {
      status: false,
      error: "Some Error Occurred",
    };
  }
};

export const getAISavedData = async (obj) => {
  try {
    const data = await axios.get(`${baseUrl}api/ai/get-saved-data`, {
      params: obj,
    });
    return {
      status: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      status: false,
      error: "Some Error Occurred",
    };
  }
};

export const updateAISavedData = async (obj) => {
  try {
    const data = await axios.post(`${baseUrl}api/ai/update-saved-data/${obj?.id}`,obj);
    return {
      status: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      status: false,
      error: "Some Error Occurred",
    };
  }
};

export const deleteAISavedData = async (id) => {
  try {
      const data = await axios.delete(`${baseUrl}api/ai/delete-saved-data/${id}`);
      return {
          status: true,
          data: data?.data,
      };
  } catch (error) {
      return {
          status: false,
          error: "Some Error Occurred",
      };
  }
};
