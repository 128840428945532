import React, { useEffect, useState } from 'react';
import { Layout, Card, Typography, Select, Table, Tag, DatePicker } from 'antd';
import * as _ from 'lodash';
import styles from './DueCardsStyle.module.scss';
import TopHeader from '../TopHeader/TopHeader';
import { useSelector, useDispatch } from 'react-redux';
import { CUSTOMER, QA, QA_USER } from '../../api/Constants';
import { errorToast, getUserShortName, infoToast, isMobile, toISODate } from '../../api/Util';
import { fetchCardsWithDueDate, getCardsBasedOnDueDate } from '../../services/cardsService';
import "./DueCardsStyle.scss";
import dayjs from 'dayjs';
import { ReloadOutlined } from '@ant-design/icons';
import ReactTooltip from 'react-tooltip';
import { getStatusAndColor } from '../CustomTabs/TabContent/Utilities/utils';
import * as actions from "../../actions/index";
import moment from 'moment';
import { CSVLink } from "react-csv";
import CustomDropdown from '../common/CustomDropdown/DropDown';
import { cardTypes } from '../../constant/DropdownData';

const INITIAL_FILTER_STATE = {
	viewAsCustomer: null,
	dueDate: toISODate(new Date()),
	viewAsQA: null,
	viewAsCardType: 'due_date',
}

const DueCards = () => {
	const [filterCards, setFilterCards] = useState(INITIAL_FILTER_STATE);
	const [tableData, setTableData] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [cardsCsvData, setCardsCsvData] = useState([]);
	const websiteUsers = useSelector(state => state.allWebsiteUsers.website_users);
	const props = useSelector((state) => state)
	const dispatch = useDispatch();
	const loggedInUser = props.auth?.loggedInUser;

	const columns = [
		{
			title: 'Card Title',
			dataIndex: 'cardTitle',
			key: 'cardTitle',
			width: 400,
		},
		{
			title: 'Customer',
			dataIndex: 'customer',
			key: 'customer',
			width: 200,
		},
		{
			title: 'QA',
			dataIndex: 'qa',
			key: 'qa',
			width: 200,
		},
		{
			title: 'Date',
			dataIndex: 'dueDate',
			key: 'dueDate',
			width: 100,
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status',
			width: 100,
			render: ((status) => {
				const statusAndColor = getStatusAndColor(status);
				return (
					<Tag color={statusAndColor?.color} key={statusAndColor?.label}>
						{statusAndColor?.label?.toUpperCase()}
					</Tag>
				)
			}),
		},
	];

	useEffect(() => {
		if (!props.users.users && !props.users.loadingUsers) {
			if (loggedInUser.client_type !== QA) {
				dispatch(actions.fetchAllUsers());
			}
		}

		if (!props?.users?.customers && !props.users.loadingCustomers) {
			dispatch(actions.fetchAllCustomers());
		}

		if (loggedInUser.client_id === 10) {
			exportCards();
		}
		getCards();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterCards])

	const setViewAsCustomer = (value) => {
		setFilterCards({ ...filterCards, viewAsCustomer: value });
	}

	const setViewAsQA = (value) => {
		setFilterCards({ ...filterCards, viewAsQA: value });
	}
	const setViewAsCardType = (value) => {
		setFilterCards({ ...filterCards, viewAsCardType: value });
	}

	const onChangeDatePicker = (date, dateString) => {
		setFilterCards({ ...filterCards, dueDate: dateString });
	};

	const getCards = async () => {
		infoToast("Loading");
		const response = await getCardsBasedOnDueDate(filterCards);
		if (response.status) {
			const filteredData = response.data?.map((item, i) => ({
				key: i + 1,
				cardTitle: item.card_title,
				customer: item?.firstname + " " + item?.lastname,
				qa: getUserShortName(item?.qa_firstname, item?.qa_lastname),
				dueDate: toISODate(item?.date_field),
				status: item.card_status,
			}))
			setTableData(filteredData);
			setTotalCount(response.data.length);
		} else {
			errorToast("Something went wrong");
		}
	}

	const exportCards = async () => {
		const res = await fetchCardsWithDueDate();
		if (res.status) {
			const filteredData = res.data?.map((item) => ({
				CardTitle: item.card_title,
				CardStstus: item.card_status,
				DueDay: item.due_day === null ? moment(item.due_date).format('dddd') : item.due_day,
				DueDate: item.due_date
			}))
			setCardsCsvData(filteredData)
		} else {
			errorToast("Something went wrong");
		}
	}

	return (
		<Layout className={styles.homeLayout}>
			<Card className={styles.topHeader} loading={false}>
				<TopHeader />
			</Card>
			<div className={styles.container}>
				<div className={styles.cardsTitleDiv}>
					<Typography.Title className={styles.cardsTitle}>
						Cards By Date
					</Typography.Title>
				</div>
				<div className={styles.filtersAndTotalCount}>
					<div className={styles.selectBoxWrapper}>
						<div>
							<CustomDropdown
								options={(websiteUsers || [])?.filter((user) => user.client_type === CUSTOMER).map(user => ({ value: user.client_id, label: `${user.fullname} (${user.client_type})` }))}
								placeholder="select-customer"
								className={styles.viewAsStyle}
								dropdownValue={filterCards.viewAsCustomer}
								handleOnchange={setViewAsCustomer}
								disabled={!filterCards?.dueDate}
							/>
						</div>
						<div className="datePickerDiv">
							<DatePicker id="datePicker" onChange={onChangeDatePicker} defaultValue={dayjs(filterCards?.dueDate, "YYYY-MM-DD")} />
						</div>
						{
							loggedInUser.client_type !== QA ? <div>
								<CustomDropdown
									options={(websiteUsers || [])?.filter(user => user.client_type === QA_USER)?.map(user => ({ value: user.client_id, label: `${getUserShortName(user.fullname)} (${user.client_type})` }))}
									placeholder="select-QA"
									className={styles.viewAsStyle}
									dropdownValue={filterCards.viewAsQA}
									handleOnchange={setViewAsQA}
									disabled={!filterCards?.dueDate}
								/>
							</div> : null
						}
						<div>
							<CustomDropdown
								options={cardTypes}
								placeholder="Card Type"
								className={styles.viewAsStyle}
								dropdownValue={filterCards.viewAsCardType}
								handleOnchange={setViewAsCardType}
								disabled={!filterCards?.dueDate}
							/>
						</div>
						<div className={loggedInUser.client_id === 10 ? styles.reloadIconDivAdmin : styles.reloadIconDiv}>
							{
								loggedInUser.client_id === 10 && <CSVLink
									data={cardsCsvData}
									asyncOnClick={true}
									className={`standard-btn ${styles.downloadDueCardsBtn} ${isMobile ? styles.dueCardExportButton : ''}`}
									filename={"Export Cards.csv"}
									target="_blank"
								>
									{isMobile ? "Export" : "Export Cards"}
								</CSVLink>
							}
							<ReloadOutlined
								data-tip="Reset Filters"
								data-place="bottom"
								className={styles.resetIcon}
								onClick={() => {
									setFilterCards({ ...filterCards, viewAsCustomer: null, viewAsQA: null, viewAsCardType: null });
								}}
							/>
							<ReactTooltip />
						</div>
					</div>
					{
						!isMobile && <div className={styles.totalCountDiv}>
							<Typography.Text className={styles.totalCount}>
								Total Count ({totalCount})
							</Typography.Text>
						</div>
					}
				</div>
				{
					isMobile && <div className={styles.totalCountDiv}>
						<Typography.Text className={styles.totalCount}>
							Total Count ({totalCount})
						</Typography.Text>
					</div>
				}
				<div className={styles.tableDiv}>
					<Table className={styles.customizeTable} columns={columns} dataSource={tableData} pagination={{ pageSize: 100 }} />
				</div>
			</div>
		</Layout>
	);
}

export default DueCards;
