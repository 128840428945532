import React, { useEffect, useState } from 'react';
import { Container, Table, Col, Row } from 'react-materialize';
import styles from './CSATStyle.module.scss';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { fetchCSATData } from '../../services/customerService';
import { toISODate } from '../../api/Util';
import Pagination from "react-js-pagination";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import CustomDatePicker from '../common/DatePicker';

const CSATTable = ({ dateDiff, addDays, activeTab, type }) => {
    const { userId } = useParams();
    const queryParams = new URLSearchParams(window.location.search);
    const startDateQueryParam = queryParams.get('startDate');
    const endDateQueryParam = queryParams.get('endDate');
    const [queryParamsValue, setQueryParams] = useState({
        queryStartDate: startDateQueryParam ? moment(decodeURIComponent(startDateQueryParam)).toDate() : null,
        queryEndDate: endDateQueryParam ? moment(decodeURIComponent(endDateQueryParam)).toDate() : null,
        queryUserType: queryParams.get('userType')
    });
    const [startDate, setStartDate] = useState(queryParamsValue?.queryStartDate || addDays(new Date(), dateDiff));
    const [endDate, setEndDate] = useState(queryParamsValue?.queryEndDate || new Date());
    const [CSATData, setCSATData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredData, setFilteredData] = useState([]);
    const [paginatedData, setPaginatedData] = useState([]);
    const itemsPerPage = 10;

    const clearQueryParams = () => {
        const newUrl = window.location.pathname;
        window.history.replaceState({}, '', newUrl);
    };

    const getCSATData = async () => {
        if (activeTab === 'CSAT') {
            try {
                const params = {
                    start_date: queryParamsValue?.queryStartDate ? toISODate(queryParamsValue?.queryStartDate) : toISODate(startDate),
                    end_date: queryParamsValue?.queryEndDate ? toISODate(queryParamsValue?.queryEndDate) : toISODate(endDate),
                    view_as: queryParamsValue?.viewAs,
                    profileType: queryParamsValue?.queryUserType === 'TeamLeadTab' ? 'team-lead' :
                        queryParamsValue?.queryUserType === 'QaTab' ? 'qa' : type
                };
                const result = await fetchCSATData(userId, params);
                if (result.status) {
                    setCSATData(result.data);
                    clearQueryParams();
                }
            } catch (error) {
                console.error('Error fetching CSAT data:', error);
            }
        }
    };

    useEffect(() => {
        if (startDate && endDate) {
            getCSATData();
        }
    }, [activeTab, startDate, endDate]);

    useEffect(() => {
        const filtered = Object.keys(CSATData)
            .filter(key => CSATData[key].rated_videos > 0 || CSATData[key].total_rating > 0);
        setFilteredData(filtered);
        setCurrentPage(1);
    }, [CSATData]);

    useEffect(() => {
        const offset = (currentPage - 1) * itemsPerPage;
        const paginated = filteredData.slice(offset, offset + itemsPerPage);
        setPaginatedData(paginated);
    }, [currentPage, filteredData]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    return (
        <Container id="response-rate-table">
            <Row>
                <Col className="response-rate-date-picker">
                    <CustomDatePicker className={`${styles.datePicker} ${styles.mobileMode}`} setStartDate={setStartDate} setEndDate={setEndDate} startDate={startDate} endDate={endDate} />
                </Col>
            </Row>
            <Table className={styles.csat_table}>
                <thead>
                    <tr>
                        <th>SR. Number</th>
                        <th>Customer Name</th>
                        <th className='text-align-left'>Card Title</th>
                        <th>Total Rated Video</th>
                        <th>Total Rating</th>
                        <th className='text-align-left'>Rated Comment</th>
                        <th>Average CSAT</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedData.length === 0 ? (
                        <tr>
                            <td colSpan="6" style={{ textAlign: 'center' }}>No data found</td>
                        </tr>
                    ) : (
                        paginatedData.map((key, index) => {
                            const client = CSATData[key];
                            return (
                                <tr key={CSATData[key]}>
                                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                    <td className="csat_client_name"><Link to={`/customer/${client.client_id}/profile`}>{`${client.client_fname} ${client.client_lname}`}</Link></td>
                                    <td className='text-align-left'>{client.card_tile}</td>
                                    <td>{client.rated_videos}</td>
                                    <td>{client.total_rating}</td>
                                    <td className="ratedComment_Section text-align-left">{client?.rating_comments}</td>
                                    <td>{client.rated_videos > 0 ? (client.total_rating / client.rated_videos).toFixed(2) : 0}</td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </Table>
            <Row className="center-align">
                {
                    filteredData.length > itemsPerPage &&
                    <Pagination
                        activePage={currentPage}
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={filteredData.length}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        prevPageText={<AiOutlineLeft />}
                        nextPageText={<AiOutlineRight />}
                        firstPageText={<AiOutlineDoubleLeft />}
                        lastPageText={<AiOutlineDoubleRight />}
                    />
                }
            </Row>
        </Container>
    );
};

export default CSATTable;
