import React, { useEffect, useState } from "react";
import { Collapsible, CollapsibleItem, Table, Icon, Card, Button, Col, Row } from "react-materialize";
import { exportTableToCSV, getUserShortName, toISODate } from "../../api/Util";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { randomNumber } from "react-ratings-declarative/build/utils";

const ARTTable = ({ data, title, tab, start, end }) => {
    const [stats, setStats] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        setStats(Object.keys(data).filter(userId => userId !== "0" && userId !== null).map(userId => ({
            id: userId,
            name: tab === "QaTab" ?
                `${data?.[userId]?.qaFirstName} ${data?.[userId]?.qaLastName}` :
                `${data?.[userId]?.leadFirstName} ${data?.[userId]?.leadLastName}`,
            total_comments_count: data?.[userId]?.total_comments_count || 0,
            replied_count: data?.[userId]?.replied_count || 0,
            avgResponseRate: (data?.[userId]?.response_rate / Math.max(data?.[userId]?.total_response_rated_cards, 1)) || 0,
            team_lead_updated: data?.[userId]?.team_lead_updated || false,
        })));
    }, [data]);

    const handleCollapsibleClick = () => {
        setIsExpanded(!isExpanded);
    };
    let tableId = title.replaceAll(/\s/g, '-') + '-' + randomNumber();
    const filteredStats = stats.filter(user => user.name?.toLowerCase()?.includes(filter.toLowerCase()));

    return (
        <Card className="dashboard-collapsible-section">
            <Collapsible accordion>
                <CollapsibleItem
                    expanded={isExpanded}
                    onClick={handleCollapsibleClick}
                    header={(
                        <div className="dashboard-collapsible-header">
                            <span className="collapsible-title">{title}</span>
                            <Icon>{isExpanded ? 'expand_less' : 'expand_more'}</Icon>
                        </div>
                    )}
                >
                    <Row>
                        <Col s={6}>{<Icon className="searchIconDashboard">search</Icon>}<input type="text" onChange={e => setFilter(e.target.value)} placeholder="Search By Name" className="searchInputDashboard" value={filter} /></Col>
                        <Col className="exportCsvButtonDashboard"><Button large flat icon={<Icon left>save</Icon>} onClick={() => exportTableToCSV(tableId, `${title} ${toISODate(start)} - ${toISODate(end)}.csv`)}>Export as CSV</Button></Col>
                    </Row>
                    <Table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th className="align-center">Total Comments</th>
                                <th className="align-center">Total Replied Count</th>
                                <th className="align-center">Average Response Rate</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredStats.map(stat => (
                                <tr key={stat.id}>
                                    {!stat.team_lead_updated ? (
                                        <Link to={`/user/${stat.id}/profile?userType=${tab}&startDate=${toISODate(start)}&endDate=${toISODate(end)}`} style={{ color: 'blue', cursor: 'pointer' }} target="_blank">
                                            <td className="align-center">{getUserShortName(stat.name)}</td>
                                        </Link>
                                    ) : (
                                        <td style={{ color: 'black', cursor: 'not-allowed', textDecoration: "line-through" }}>{getUserShortName(stat.name)}</td>
                                    )}
                                    <td className="align-center">{stat?.total_comments_count}</td>
                                    <td className="align-center">{stat?.replied_count}</td>
                                    <td className="align-center">{stat?.avgResponseRate.toFixed(2)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CollapsibleItem>
            </Collapsible>
        </Card>
    );
};

export default ARTTable;
