import React, { useEffect, useState } from 'react';
import { Drawer, Typography, Timeline } from 'antd';
import { getNotificationReadHistory } from '../../../services/notificationService';
import styles from './NotificationListingStyle.module.scss';
import { convertServerToLocalTime } from '../../../api/Util';

const NotificationDrawer = ({ isOpen, setIsOpen, uniqueId }) => {
    const [notificationData, setNotificationData] = useState([]);

    const fetchNotificationData = async () => {
        setNotificationData([]);
        const response = await getNotificationReadHistory(uniqueId);
        if (response.status === 200) {
            const sortedData = response.data.sort((a, b) => new Date(b.read_at) - new Date(a.read_at));
            setNotificationData(sortedData);
        } else {
            console.error(response.error);
        }
    };

    useEffect(() => {
        if (isOpen && uniqueId) {
            fetchNotificationData();
        }
    }, [isOpen, uniqueId]);

    return (
        <div>
            <Drawer
                title="Notification History"
                placement="right"
                closable={false}
                onClose={() => setIsOpen(false)}
                open={isOpen}
                className={styles.notificationDrawer}
            >
                <Timeline>
                    {notificationData.map((notification, index) => (
                        <Timeline.Item key={index} color={notification.read_at ? 'green' : 'red'}>
                            <Typography.Text strong>
                                {notification?.read_at ? convertServerToLocalTime(notification.read_at) : 'Not Read'}
                            </Typography.Text>
                            <br />
                            {`${notification.firstname} ${notification.lastname} (${notification.client_type})`}
                        </Timeline.Item>
                    ))}
                </Timeline>
            </Drawer>
        </div>
    );
};

export default NotificationDrawer;
