import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardPanel,
    Checkbox,
    Col,
    Dropdown,
    Icon,
    Row,
} from "react-materialize";
import * as _ from 'lodash';
import {connect, useDispatch, useSelector} from 'react-redux';
import * as actions from "../../../../actions";
import { getUserShortName, isAdmin, isCustomer, isTeamLead, toISODate } from "../../../../api/Util";
import { CUSTOMER, QA_USER, YT_HELPER } from '../../../../api/Constants';
import Clock from 'react-live-clock';
import styles from './KanbanViewHeaderStyle.module.scss';
import TimeClock from '../../../common/TimeClock/TimeClock';
import SearchBar from '../../../SearchBar';
import { Link } from 'react-router-dom';
import KanbanAndListViewToggle from '../../../common/KanbanAndListViewToggle/KanbanAndListViewToggle';
import { Select } from 'antd';
import CustomDatePicker from '../../../common/DatePicker';
import { CLEAR_CARD_SECTIONS } from '../../../../actions/types';

export default connect(mapStateToProps, actions)((props) => {
    const selectedDueDate = props.selectedDueDate;
    const showHold = props.showHold;
    const showCanceled = props.showCanceled;
    const showRepurposed = props.showRepurposed;
    const viewAs = props?.viewAs;
    const searchText = props.searchText; 
    const [clearFilterState, setClearFilterState] = useState(false);
    const loggedInUser = props.auth?.loggedInUser;
    const admin = isAdmin(loggedInUser);
    const customer = isCustomer(loggedInUser);
    const dispatch = useDispatch();
    const viewType = localStorage.getItem("viewType");
    const requestsLimited = customer && loggedInUser.request_limit;
    const accountPending = customer && loggedInUser.qas?.length <= 0;
    const timezone =  process.env.REACT_APP_TIMEZONE;
    const websiteUsers = useSelector(state => state.allWebsiteUsers.website_users);
    function setViewAs(v) {
        localStorage.setItem("viewAs", v);
        props.setViewAsState(v);
        refresh(v);
    }

    const refresh = (user) => {
        if (props?.queryParamsValue?.querySectionType) {
            props.setSearchText("");
        }
        let obj = {
            offset : 0,
            limit:10,
            section : props?.queryParamsValue?.querySectionType || "",
            card_name: searchText ? searchText:"",
            viewType:viewType,
        }
        if (props.startDate && props.endDate)
        {
            obj = {
                ...obj,
                startDate:toISODate(props.startDate),
                endDate:toISODate(props.endDate),
                selectedDueDate
            }
        }
        setClearFilterState(false);
        // eslint-disable-next-line no-unused-expressions
        props?.fetchCards(obj,user || viewAs);
    };
    const clearQueryParams = () => {
        const newUrl = window.location.pathname;  
        window.history.replaceState({}, '', newUrl);
    };
    
    const clearFilter = () => {
        setClearFilterState(true);
        props.setStartDate(null);
        props.setEndDate(null);
        props.setSearchText("");
        props.setViewAsState(null);
        localStorage.removeItem("viewAs");
        localStorage.removeItem("searchText");
        props.setQueryParams();
        clearQueryParams();
        
        //refresh();
    }

    useEffect(() => {
        if (clearFilterState) {
            dispatch({
                type: CLEAR_CARD_SECTIONS
            });
           refresh();
        }
    },[clearFilterState]);
    
    const activeFilterCount = () => {
        let count = 0;
        if (props?.startDate && props?.endDate) count++;
        if (viewAs) count++;
        if (searchText) count++;
        return count;
    };

    const showCreateCard = () => {
        if(!(requestsLimited || accountPending)) {
            props.setNewCardRequestOpen(true);
        }
    }

    const searchCards = () =>{
        let obj = {
            offset : 0,
            limit:10,
            section : "",
            card_name: searchText ? searchText:"",
            viewType:viewType,
        }
        localStorage.setItem('searchText', searchText)
        props.fetchCards(obj,viewAs);
    }
    const handleShowCanceledChange = () => {
        const updatedValue = !props.showCanceled;
        localStorage.setItem("showCanceled", updatedValue);
        props.setShowCanceled(updatedValue);
    };

    const handleShowHoldChange = () => {
        const updatedValue = !props.showHold;
        localStorage.setItem("showHold", updatedValue);
        props.setShowHold(updatedValue);
    };

    const handleShowRepurposedChange = () => {
        const updatedValue = !props.showRepurposed;
        localStorage.setItem("showRepurposed", updatedValue);
        props.setShowRepurposed(updatedValue);
    };


    return (
        <div>
            <div className={`aside-right-top-bar ${styles.searchBarAndBtns}`}>
                <div className={`top-bar-left ${styles.topBarLeftStyle}`}>
                    <div className={`refresh-create-btn ${styles.refreshAndCreateBtn}`}>
                        <Button icon={<Icon>refresh</Icon>} tooltip="Reload Cards" onClick={()=> refresh()}
                            className="btn btn-primary" />
                        {
                            (admin || customer) &&
                            <Link to={{ pathname: `/new-card` }}>
                                <Button icon={<Icon>add</Icon>}
                                    tooltip={requestsLimited ? `You have reached limit for video requests. Your limit will refresh on the next billing date. For further information contact your account manager.`
                                        : accountPending ? "We are setting up your account for file sharing, we will update you via email once it is done."
                                            : "Create New Video Request"} 
                                    className={"btn btn-primary" + ((requestsLimited || accountPending)
                                        ? "btn-secondary" : "btn-primary")}/> 
                            </Link>
                        }
                    </div>
                </div>
                <div className="top-bar-center">
                    <div className="top_bar">
                        <SearchBar cards={props.cardList} setSearchText={props.setSearchText} searchText={searchText} searchCards={searchCards} />
                    </div>
                </div>
                <KanbanAndListViewToggle
                    selectedListingView={props.selectedListingView}
                    setSelectedListingView={props.setSelectedListingView}
                    isBtnDisabled={props.isBtnDisabled}
                    setIsBtnDisabled={props.setIsBtnDisabled}
                    />
            </div>
            <div className={`aside-top-filters ${styles.filtersDiv}`}>
                <Row className={`hide-on-med-and-down difplay_flext ${styles.rowStyle}`}>
                    {
                        loggedInUser.client_type !== CUSTOMER && viewType === "normal" ?
                        <Col className="date-filter-col">
                            <CustomDatePicker className="date-picker-kanban-view" 
                                setStartDate={props?.setStartDate}
                                setEndDate={props?.setEndDate}
                                startDate={props?.startDate} 
                                endDate={props?.endDate}
                                setSelectedDueDate={props?.setSelectedDueDate}
                                selectedDueDate={selectedDueDate}
                            />
                        </Col>:null
                    }
                    {
                        (admin || isTeamLead(loggedInUser)) &&
                        <Col className="view-input-col">
                             <Select className="z-depth-1 select-view-as-dashboard"
                                showSearch
                                value={(websiteUsers?.length) ? (viewAs ? parseInt(viewAs) : undefined) : undefined}
                                onChange={setViewAs}
                                optionFilterProp="children"
                                filterOption={(input, option) => 
                                    (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}     
                                    options={_.concat(
                                        [{
                                            ...loggedInUser, value: loggedInUser.client_id, label: `Me (${loggedInUser.client_type})`
                                        }], websiteUsers.map(user => ({ value: user.client_id, label: user.client_type === 'customer' ? `${user.fullname} (${user.client_type})` : `${getUserShortName(user.fullname)} (${user.client_type})` }))
                                    )}
                                placeholder="View As"
                            />
                        </Col>
                    }
                    <Col className="toggle-panel-section-kanban">
                        <CardPanel className="toggle-panel">
                            <Checkbox value="1" label="Show Canceled Cards" checked={showCanceled} id="check-canceled"
                                    onChange={handleShowCanceledChange}/>
                        </CardPanel>
                    </Col>
                    { viewType === "normal" && 
                    <>
                    <Col className="toggle-panel-section-kanban">
                        <CardPanel className="toggle-panel">
                            <Checkbox value="2" label="Show Cards On Hold" checked={showHold} id="check-hold"
                                    onChange={handleShowHoldChange}/>
                        </CardPanel>
                    </Col>
                    <Col className="toggle-panel-section-kanban">
                        <CardPanel className="toggle-panel">
                            <Checkbox value="2" label="Show Cards In Repurpose" checked={showRepurposed} id="check-rp"
                                    onChange={handleShowRepurposedChange}/>
                        </CardPanel>
                    </Col>
                    </>
                    }
                    <Col className="real-date">
                        <CardPanel style={{margin: 0, borderRadius: "8px", minWidth: '265px'}}>
                            <TimeClock
                                timezone={timezone}
                                clockDivStyle={styles.timezoneDiv}
                                format={"ddd, DD-MMM-YYYY | hh:mm:ss"}
                                view={"kanbanView"}
                                />
                        </CardPanel>
                    </Col>
                    {
                        customer && (props.cards.monthlyCardCount || props.cards.monthlyCardCount === 0) ? <Col>
                            <CardPanel className='month-card-created-cardpanel'>
                                <span>Monthly Card Created Count : </span>
                                <span style={{ margin: '0 4px 0px 4px' }}>{props.cards.monthlyCardCount} </span>
                            </CardPanel>
                        </Col>: null
                    }
                    <Col className="cutomer-filter-clear-btn">
                        <Button className='home-clear-filter' style={{ height: '44px' }} onClick={()=> clearFilter()}>Clear Filter{activeFilterCount() > 0 && `(+${activeFilterCount()})`}</Button>
                    </Col>
                </Row>
                <Row className="hide-on-large-only hide-on-extra-large-only mobile-control-btns">
                    <Col s={2} m={4}>
                        <Dropdown
                            trigger={<Button style={{backgroundColor: "#82b150", marginTop: "5px"}} floating large icon={<Icon>more_vert</Icon>}/>}
                            options={{
                                autoTrigger: false,
                                constrainWidth: false,
                                coverTrigger: false,
                                closeOnClick: false,
                            }}>
                            {React.Children.toArray([
                                (admin || customer) && !(requestsLimited || accountPending) ?
                                <Button icon={<Icon left>add</Icon>} flat onClick={showCreateCard} style={{margin: '5px'}}>
                                    Create New Request</Button> : null,
                                accountPending ?
                                    <p style={{margin: '20px'}}>Your DropBox video account is in progress, we will send you an email when we are done then you will be able create video request.</p>
                                    : null,
                                requestsLimited ?
                                    <p style={{margin: '20px'}}>You have reached limit for video requests. Your limit will refresh on the next billing date. For further information contact your account manager.</p>
                                    : null,
                                <Button icon={<Icon left>refresh</Icon>} flat onClick={()=> refresh()} style={{margin: '5px'}}>Refresh</Button>,
                                <div style={{margin: '20px'}}>
                                    <Checkbox value="1" label="Show Canceled Cards" checked={showCanceled} id="check-canceled"
                                            onChange={handleShowCanceledChange}/>
                                </div>,
                                <div style={{margin: '20px'}}>
                                    <Checkbox value="2" label="Show Cards On Hold" checked={showHold} id="check-hold"
                                            onChange={handleShowHoldChange}/>
                                </div>,
                                <div style={{margin: '20px'}}>
                                    <Checkbox value="2" label="Show Cards In Repurpose" checked={showHold} id="check-repurp-2"
                                            onChange={handleShowRepurposedChange}/>
                                </div>,
                                (admin || isTeamLead(loggedInUser)) &&
                                <Select
                                    className="z-depth-1 select-view-as-dashboard"
                                    showSearch
                                    value={(websiteUsers?.length) ? (viewAs ? parseInt(viewAs) : undefined) : undefined}
                                    onChange={setViewAs}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())
                                    }
                                    options={_.concat(
                                        [{
                                            ...loggedInUser, value: loggedInUser.client_id, label: `Me (${loggedInUser.client_type})`
                                        }], websiteUsers.map(user => ({ value: user.client_id, label: user.client_type === 'customer' ? `${user.fullname} (${user.client_type})` : `${getUserShortName(user.fullname)} (${user.client_type})` }))
                                    )}
                                    placeholder="View As"
                                />                            
                            ])}
                        </Dropdown>
                    </Col>
                    <Col s={10} m={8}>
                        <CardPanel style={{margin: 0, fontSize: "1.2rem"}}>
                            <Icon left>access_time</Icon>
                            <Clock
                                format={"DD-MMM-YYYY hh:mm:ss"}
                                ticking={true}
                                interval={1000}
                                timezone={timezone}
                            />
                            <span> EST</span>
                        </CardPanel>
                    </Col>
                </Row>
            </div>
        </div>
    );
});
function mapStateToProps({cards, auth, users, settings}) {
    return {cards, auth, users, settings};
}
