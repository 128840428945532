import React, { useEffect, useState } from 'react';
import { Typography, Button, Input, Card, Tooltip, Avatar, Empty, Select } from 'antd';
import styles from './MessageList.module.scss';
import * as _ from 'lodash';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Pagination from "react-js-pagination";
import moment from 'moment';
import { useSelector } from 'react-redux';
import './MessageListStyle.scss';
import { CommentOutlined } from '@ant-design/icons';
import { ADMIN, CUSTOMER, TEAM_LEAD } from '../../../api/Constants';
import ConfirmationDialog from "../../ConfirmationDialog";
import { seenLatestMessage } from '../../../services/inboxService';
import { convertServerToLocalTime } from '../../../api/Util';
import CustomDropdown from '../../common/CustomDropdown/DropDown';

const MessageList = ({
	status,
	filterMessages,
	setFilterMessages,
	setIsModalOpen,
	messages,
	isLoading,
	rowsCount,
	limit,
	page,
	setPage,
	setState,
	setCurrentSelectedMessage,
	getChatById,
	setChatTitle,
	setHasMore,
	setMessages,
	setIsLoading,
	getChatInboxMessages,
	setAddTitle,
	handleInboxMessage,
	currentSelectedMessage,
	updatedCount,
	cardClick,
	isMobile,
}) => {
	const [confirmationOpen, setConfirmationOpen] = useState({ open: false, id: "" });
	const props = useSelector((state) => state);
	const loggedInUser = props.auth.loggedInUser;
	const websiteUsers = useSelector(state => state.allWebsiteUsers.website_users);
	const handleCardClick = (card, seenArray, i) => {
		setHasMore(true);
		setCurrentSelectedMessage(card.id);
		setChatTitle(card.title);
		setState({ comments: {}, commentsArray: [] });
		getChatById('noCall', card.id, 0);
		seenArray.push(loggedInUser.client_id);
		if (card.status === "ACTIVE" && !(seenArray.includes(loggedInUser.client_id.toString()))) {
			seenLatestMessage(card.id, card.seen);
			document.getElementById(i + "_card_style").style.border = 'none';
			document.getElementById(i + "_comment_outlined").style.color = '#616161';
			document.getElementById(i + "_total_count").style.color = '#616161';
		}
		if (isMobile) {
			cardClick();
		}
	}

	const setViewAs = (v) => {
		setMessages([]);
		setPage(1);
		setIsLoading(true);
		setChatTitle("");
		setState({ comments: {}, commentsArray: [] });
		setFilterMessages({ view_as: v });
	}

	useEffect(() => {
		const newState = messages.map(obj => {
			if (obj.id === currentSelectedMessage) {
				return { ...obj, total_count: updatedCount };
			}
			return obj;
		});
		setMessages(newState);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updatedCount])

	useEffect(() => {
		setMessages([]);
		setIsLoading(true);
		getChatInboxMessages();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterMessages, page])

	return (
		<div>
			<ConfirmationDialog
				onNegative={() => setConfirmationOpen(false)}
				onPositive={() => handleInboxMessage(confirmationOpen.id, confirmationOpen.status)}
				confirmationHeader="Caution"
				confirmationDialogId={confirmationOpen.id}
				confirmation={confirmationOpen.open}
				confirmationText={`Are you sure you want to ${confirmationOpen.status} message?`}
			/>
			<div className={`${styles.msgCountAndDivWrapper} msgCountAndDivWrapper`}>
				<Typography.Text strong className={styles.mobileMode}>
					Total Messages ({rowsCount})
				</Typography.Text>
				<div className={styles.searchFieldAndCreateBtnWrapper}>
					{
						(loggedInUser.client_type === TEAM_LEAD || loggedInUser.client_type === ADMIN) &&
						<CustomDropdown
							options={_.concat(websiteUsers || [])
								.filter(user => user.client_type === CUSTOMER)
								.map(user => ({ value: user.client_id, label: user.fullname }))
							}
							placeholder="View As"
							className={styles.viewAsStyle}
							dropdownValue={filterMessages.view_as}
							handleOnchange={setViewAs}
						/>
					}
					<Input.Search
						placeholder="Search Message"
						allowClear
						className={styles.searchInput}
						onSearch={(value) => setFilterMessages({ ...filterMessages, message_title: value })}
					/>
					{
						(status === "ACTIVE" && loggedInUser.client_type === CUSTOMER)
						&& <Button type="primary" className={styles.newMessageBtn} onClick={() => setIsModalOpen(true)}>Add New Message</Button>
					}
				</div>
				<Typography.Text strong className={styles.deskMode}>
					Total Messages ({rowsCount})
				</Typography.Text>
			</div>
			<div className={styles.cardWrapper}>
				{
					!messages?.length > 0 && <Card className={styles.cards} loading={isLoading}>
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No Message Found"} />
					</Card>
				}
				{messages && messages?.map((card, i) => {
					let seenArray = card.seen.split(',');
					return <Card
						id={i + "_card_style"}
						style={{ border: card.status === "ACTIVE" && !(seenArray.includes(loggedInUser.client_id.toString())) ? '1px solid green' : 'none' }}
						title={
							<div className={styles.titleWrapper}>
								<Typography.Text>{card.title}</Typography.Text>
								{
									loggedInUser.client_type !== CUSTOMER &&
									<button
										className={card.status === "ACTIVE" ? `standard-btn standard-btn-normal-padding ${styles.resolveBtn}` : `standard-btn standard-btn-normal-padding  ${styles.disableResolveBtn}`}
										disabled={card.status === "ACTIVE" ? false : true}
										onClick={(e) => { e.stopPropagation(); setConfirmationOpen({ open: true, id: card.id, status: 'Resolve' }) }}
									>{card.status === "ACTIVE" ? isMobile ? "Resolve" : "Click to Resolve" : "Resolved"}
									</button>
								}
								{
									loggedInUser.client_type === CUSTOMER && card.status === "RESOLVED" &&
									<button
										className={styles.resolveBtn}
										onClick={(e) => { e.stopPropagation(); setAddTitle(card.title); setIsModalOpen({ open: true, id: card.id, status: 'Re-open' }) }}
									>{isMobile ? "Re-open" : "Click to Re-open"}
									</button>
								}
							</div>
						}
						bordered={false}
						className={`${styles.cards} cardsStyle`}
						onClick={() => handleCardClick(card, seenArray, i)}
					>
						<div className={styles.avatarAndMessageWrapper}>
							<Tooltip title={
								card?.client_firstname || card?.client_lastname ? `${card?.client_firstname || ''} ${card?.client_lastname || ''}` : 'N/A'
							} placement="top">
								<Avatar className={styles.messageListAvatar}>
									{card?.client_firstname || card?.client_lastname ?
										`${card?.client_firstname.toUpperCase().charAt(0)}${card?.client_lastname.toUpperCase().charAt(0)}` : 'N/A'}
								</Avatar>
							</Tooltip>
							<div style={{ width: '100%' }}>
								<div className={styles.msgAndCountWrapper}>
									<Typography.Text>{card.latest_message}</Typography.Text>
									<div className={styles.iconAndTextContainer}>
										<CommentOutlined id={i + "_comment_outlined"} className={card.status === "ACTIVE" && !(seenArray.includes(loggedInUser.client_id.toString())) ? styles.greenFontColor : styles.greyFontColor} />
										<Typography.Text id={i + "_total_count"} className={card.status === "ACTIVE" && !(seenArray.includes(loggedInUser.client_id.toString())) ? styles.greenFontColor : styles.greyFontColor}>{card.total_count}</Typography.Text>
									</div>
								</div>
								<span className={styles.dateStyle}>{moment(convertServerToLocalTime(card.created_at)).format('ll')}</span>
							</div>
						</div>
					</Card>
				})}
			</div>
			{
				rowsCount > limit &&
				<div className={`center-align ${styles.paginationDiv}`}>
					<Pagination
						className={styles.paginationStyle}
						activePage={page}
						itemsCountPerPage={10}
						totalItemsCount={rowsCount}
						pageRangeDisplayed={10}
						onChange={i => setPage(i)}
						prevPageText={<AiOutlineLeft />}
						nextPageText={<AiOutlineRight />}
						firstPageText={<AiOutlineDoubleLeft />}
						lastPageText={<AiOutlineDoubleRight />}
					/>
				</div>
			}
		</div>
	)
}

export default MessageList;
