import React, { useState } from 'react';
import { Button, Input, Select } from 'antd';
import { ToolType } from '../../../constant/AIdata';
import styles from "./AITable.module.scss";
import { FaSearch } from 'react-icons/fa';
import { debounce } from 'lodash';

const AIDataFilters = ({filterParams, setFilterParams }) => {

  const handleInputChange = (value, name) => {
    setFilterParams((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const debouncedHandleInputChange = debounce((value) => {
    handleInputChange(value, 'titleSearch');
  }, 500);

  return (
    <div className={`${styles.searchFieldAndSelectBtnWrapper}`}>
        <div className={`${styles.searchField}`}>
        <Select
          placeholder="Select Tool Type"
          value={filterParams.tooltype}
          className={`${styles.selectFieldForType}`}
          onChange={(value) => handleInputChange(value, 'tooltype')}
          options={[
            { value: '', label: 'All' },
            ...ToolType.map((type) => ({
              value: type.value,
              label: type.title,
            }))
          ]}
        />
      </div>
      <div className={`${styles.selectField}`}>
        <Input.Search
          placeholder="Search by Title"
          style={{
            width: '100%',
          }}
          name="title"
          className={`${styles.selectFieldForSearch}`}
          onChange={(e) => debouncedHandleInputChange(e.target.value)}
        />
         <Button className={`${styles.searchButton}`} onClick={() => setFilterParams((prev) => ({...prev, fetch: !prev.fetch}))} ><FaSearch/></Button>
      </div>

    </div>
  );
};

export default AIDataFilters;
