import React, { useState, useEffect } from "react";
import { Table, Row, Button, Modal } from "react-materialize";
import AIDataFilters from "./AIDataFilters";
import { deleteAISavedData, getAISavedData, updateAISavedData } from "../../../services/aiToolboxService";
import { Spin } from "antd";
import "../AIToolboxModal/AIToolboxModal.scss";
import { infoToast, stripHtmlTags, successToast } from "../../../api/Util";
import Pagination from "react-js-pagination";
import {
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight,
} from "react-icons/ai";
import { FaEdit, FaTrash, FaCopy } from "react-icons/fa";
import styles from "./AITable.module.scss";
import AIEditor from "./AIEditor";

const AIDataTable = ({loggedInUser}) => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterParams, setFilterParams] = useState({
    titleSearch: "",
    tooltype: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCardsCount, setTotalCardsCount] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editorValue, setEditorValue] = useState("");

    const fetchData = async () => {
      infoToast("....Loading");
      try {
        const response = await getAISavedData({
          ...filterParams,
          page: currentPage,
          userId:loggedInUser?.client_id,
          userType:loggedInUser?.client_type,
        });
        if (response.status || 1) {
          setTableData(response?.data.data);
          setTotalCardsCount(response?.data.totalCount); // Update total count from response
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    useEffect(() => {
      fetchData();
    }, [filterParams, currentPage]);

  const handleEditClick = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    setIsModalOpen(false); // Close the modal
  };

  const renderDataCell = (data) => {
    const plainText = stripHtmlTags(data);
    return (
      <td title={data}>
        {data.length > 70 ? `${plainText.substring(0, 70)}...` : plainText}
      </td>
    );
  };
  const handleUpdateAISavedData = async () => {
    try {
      let params = {
        id:selectedItem.id,
        data:editorValue,
        userId:loggedInUser?.client_id,
      }
      const response = await updateAISavedData(params); 
      if (response.status) {
        infoToast("Data updated successfully");
        setIsModalOpen(false);
        fetchData();
      } else {
        infoToast("Error updating data");
      }
    } catch (error) {
      console.error("Error updating data:", error);
      infoToast("Error updating data");
    }
  };

  const handleDeleteClick = async (id) => {
    try {
        const response = await deleteAISavedData(id);
        if (response.status) {
            infoToast("Data deleted successfully");
            fetchData();
        } else {
            infoToast("Error deleting data");
        }
    } catch (error) {
        console.error("Error deleting data:", error);
        infoToast("Error deleting data");
    }
  };

  const handleCopyClick = (item) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = item.data;
    const textContent = tempElement.textContent || tempElement.innerText || '';
    navigator.clipboard.writeText(textContent)
      .then(() => {
        successToast('Text copied successfully');
      })
      .catch((error) => {
        console.error('Error copying text:', error);
      });
  }
  
  
  return (
    <div className="card-content">
      <AIDataFilters
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />
      <Table className={`${styles.AIDataTable}`}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>S.No</th>
            <th style={{ width: "10%" }}>Title</th>
            <th style={{ width: "15%" }}>Audience Type</th>
            <th style={{ width: "10%" }}> Category</th>
            <th style={{ width: "10%" }}>Duration</th>
            <th className={styles.dataColumns} style={{ width: "40%" }}>Data</th>
            <th style={{ width: "10%" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.length < 1 ? (
            <tr>
              <td colSpan="7">No data found</td>
            </tr>
          ) : (
            tableData?.map((item, index) => (
              <tr key={index}>
                <td>{(currentPage - 1) * 10 + index + 1}</td>
                <td>{item.title}</td>
                <td>{item.audience_type || '---'}</td>
                <td>{item.category || '---'}</td>
                <td>{(item.duration && item.duration.replace(/_/g, ' ')) || '---'}</td>
                 {renderDataCell(item.data)}
                <td className={styles.aiTableActionButton}>
                  <Button className={`${styles.editButton} btn-danger`} onClick={() => handleEditClick(item)}>
                    <FaEdit />
                  </Button>
                  <Button className={`${styles.deleteButton} btn-danger`} onClick={() => handleDeleteClick(item.id)}>
                    <FaTrash />
                  </Button>
                  <Button className={`${styles.copyButton} btn-danger`} onClick={() => handleCopyClick(item)}>
                    <FaCopy />
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      {totalCardsCount > 0 ? (
        <Row className="center-align">
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={10}
            totalItemsCount={totalCardsCount}
            pageRangeDisplayed={5}
            onChange={(page) => setCurrentPage(page)}
            prevPageText={<AiOutlineLeft />}
            nextPageText={<AiOutlineRight />}
            firstPageText={<AiOutlineDoubleLeft />}
            lastPageText={<AiOutlineDoubleRight />}
          />
        </Row>
      ) : null}
         {selectedItem && (
        <div className={`${styles.modalEditor}`}>
          <Modal
              actions={[
                  <Button flat modal="close" node="button" className="btn-danger" large>Close</Button>,
                  <Button modal="close" onClick={handleUpdateAISavedData}  node="button"
                    waves="green" className="btn-primary" large>Update</Button>
              ]}
              header={`${selectedItem?.title || ""}`}
              id="addCustomerModal"
              className="AIEditModal"
              open={isModalOpen}
              style={{maxHeight: '72vh'}}
              options={{
                  dismissible: true,
                  endingTop: '10%',
                  opacity: 0.5,
                  preventScrolling: true,
                  onCloseEnd: () => setIsModalOpen(false)
              }}
              >
              <AIEditor
              item={selectedItem}
              onClose={handleCloseModal}
              editorValue={editorValue}
              setEditorValue={setEditorValue}
            />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default AIDataTable;
