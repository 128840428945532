import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import * as actions from "../actions";
import Clock from 'react-live-clock';
import {
    Button,
    Card,
    CardPanel,
    Checkbox,
    Col,
    Collapsible,
    CollapsibleItem,
    Container,
    Dropdown,
    Icon,
    ProgressBar,
    Row,
    Modal,
} from "react-materialize";
import Select from 'react-select';
import * as _ from 'lodash';
import CardList from "./CardList";
import CreateCard from "./CreateCard";
import CardModal from "./CardModal";
import EditCard from "./EditCard";
import {cssStringToObj, decodeBase64, getCardListConfig, getUserShortName, infoToast, isAdmin, isCustomer, isTeamLead,successToast,toISODate} from "../api/Util";
import {CANCELED, DONE, EDITING, ON_HOLD, QA, QA_USER, VIDEO_REQUEST, YT_HELPER} from "../api/Constants";
import DragNDrop from "./DragNDrop";
import PubSub from 'pubsub-js';
import {CARD_CLICKED} from "../api/Events";
import {useLocation} from "react-router-dom";
import SearchBar from './SearchBar';

import TopHeader from './TopHeader';
import {DateRangePicker} from 'react-date-range';
import { fetchCardsBasedOnSection } from '../services/cardsService';
import { useDispatch } from 'react-redux';
import { CARDS_FETCHED } from '../actions/types';
import { axiosClient } from '../api/httpClient';

export default connect(mapStateToProps, actions)((props) => {

    const [key, setKey] = useState(1);
    const [newCardRequestOpen, setNewCardRequestOpen] = useState(false);
    const [viewCardOpen, setViewCardOpen] = useState(false);
    const [editCardOpen, setEditCardOpen] = useState(false);
    const [viewCardId, setViewCardId] = useState(0);
    const [editCardId, setEditCardId] = useState(0);
    const [viewAs, setViewAsState] = useState(null);
    const [showCanceled, setShowCanceled] = useState(false);
    const [showHold, setShowHold] = useState(true);
    const [showRepurposed, setShowRepurposed] = useState(true);
    const location = useLocation();

    const [searchText, setSearchText] = useState();

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [selectedDueDate, setSelectedDueDate] = useState(false);
    const [cardsData, setCardsData] = useState({});
    const [addDateRangeModalOpen, setAddDateRangeModalOpen] = useState(false);
    const [clearFilterState, setClearFilterState] = useState(false);

    const loggedInUser = props.auth?.loggedInUser || {};

    const admin = isAdmin(loggedInUser);
    const customer = isCustomer(loggedInUser);
    const teamLead =isTeamLead(loggedInUser);

    const requestsLimited = customer && loggedInUser.request_limit;
    const accountPending = customer && loggedInUser.qas?.length <= 0;

    const showProfileMsg = localStorage.getItem("skipProfileForNow");
    const dispatch = useDispatch();

    function setViewAs(v) {
        setViewAsState(v);
        refresh(v.value);
    }

    const refresh = (user) => {
        setSearchText("");
        let obj = {
            offset : 0,
            limit:10,
            section : "",
            card_name:"",
        }

        if(startDate && endDate)
        {
            obj = {
                ...obj,
                startDate:toISODate(startDate),
                endDate:toISODate(endDate),
                selectedDueDate
            }
        }

        props.fetchCards(obj,user || viewAs?.value);
    };
    const showCreateCard = () => {
        if(!(requestsLimited || accountPending)) {
            setNewCardRequestOpen(true);
        }
    }
    const showEditCard = (cardId) => {
        setEditCardId(cardId);
        setEditCardOpen(true);
    }
    const showCard = (cardId) => {
        setViewCardId(cardId);
        setViewCardOpen(true);
    }
    const searchCards = () =>{
        let obj = {
            offset : 0,
            limit:10,
            section : "",
            card_name: searchText ? searchText:"",
        }
        props.fetchCards(obj,viewAs?.value);
    }
    const activeFilterCount = () => {
        let count = 0;
        if (startDate && endDate) count++;
        if (viewAs) count++;
        if (searchText) count++;
        return count;
    };

    useEffect(() => {
        if (props?.location?.search) {
            let encodeCardId = props.history.location.search.slice("?cardId=".length);
            let cardId = decodeBase64(encodeCardId);
            showCard(cardId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.location?.search])

    useEffect(() => {
        let cardId = location.hash?.replace(/#card-/i, "");
        if (cardId && (props?.cards?.cardMap || {})[cardId]) {
            showCard(cardId);
        }
    }, [props.cards.cardMap, location.hash]);

    useEffect(() => {
        const subToken = PubSub.subscribe(CARD_CLICKED, (msg, data) => showCard(data));

        if(_.isEmpty(props.settings)) {
            props.fetchSettings();
        }

        if((admin || teamLead ) && props.users.qas == null && !props.users.loadingUsers) {
            props.fetchAllUsers();
        }
        if ((admin || teamLead ) && props.users.customers === undefined && !props.users.loadingCustomers) {
            props.fetchAllCustomers();
        }
        return () => PubSub.unsubscribe(subToken);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewAs, admin]);

    useEffect(() => {
        if ((startDate && endDate) || clearFilterState) {
            refresh();
            setClearFilterState(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate,endDate,selectedDueDate, viewAs])

    function onDateChange(dates) {
        setStartDate(dates.selection.startDate);
        setEndDate(dates.selection.endDate);
    }

    const bannerTextStyle = Object.assign(cssStringToObj(props.settings?.banner_text_style || ""),
        {whiteSpace: "pre-wrap"}
        );

    const clearFilter = () => {
        setStartDate();
        setEndDate();
        setViewAsState(null);
        setClearFilterState(true);
    }

    useEffect(() => {
        let cardStatus = [
            { status: "video_request", key: "video_requests" },
            { status: "on_hold", key: "on_hold" },
            { status: "editing", key: "editing" },
            { status: "qa", key: "qa_cards" },
            { status: "done", key: "done_cards" },
            { status: "canceled", key: "cancelled_cards" },
            { status: "repurposeEditing", key: "repurposeEditing" },
            { status: "repurposeQA", key: "repurposeQA" },
            { status: "repurposeDone", key: "repurposeDone" },
        ];
        cardStatus && cardStatus.map((data, i) => {
            let obj = {
                offset : 0,
                limit:10,
                section : data.status,
                card_name:"",
            }
            getCards(obj, data.key);
        })
    }, [])
    

    async function getCards(obj, key) {
        const res = await fetchCardsBasedOnSection(obj);
        setCardsData(prevState => ({...prevState, [key]: res.data[key]}));
    }

    useEffect(() => {
        if (Object.keys(cardsData).length > 0) {
            dispatch({
                type: CARDS_FETCHED,
                payload: { status: 200, data: cardsData }
            })
        }

        if (loggedInUser.client_id === 1622) {
            infoToast("Loading...");
            axiosClient.post(`/api/uploadVideo`, {
                link: "https://videoportal-video.nyc3.cdn.digitaloceanspaces.com/1170/How-To-Ship-Your-Cookies-So-They-Arrive-Safely.mp4"
            }).then(({data}) => {
                console.log("dATA- ", data)
                successToast("Loaded...")
            }).catch(err => {
               console.error("error- ", err);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardsData])

    return (
        <Container>
            <DragNDrop loggedInUser={loggedInUser} refresh={refresh} setKey={setKey}/>
            <CardModal cardId={viewCardId} isOpen={viewCardOpen} onClose={setViewCardOpen.bind(this, false)} refresh={refresh}
                       qas={props.users.qas} users = {props.users.users} me={loggedInUser} />
            <EditCard cardId={editCardId} me={loggedInUser} isOpen={editCardOpen} onClose={() => setEditCardOpen(false)}/>
            <CreateCard me={loggedInUser} isOpen={newCardRequestOpen} onClose={() => {setNewCardRequestOpen(false); refresh();}}/>
            {props.cards.loading ? <Row><ProgressBar /></Row> : ""}
            {
                props.settings?.enable_banner &&
                <Collapsible style={{ margin: 0 }}>
                    <CollapsibleItem
                        className="notification-collapsible"
                        expanded={true}
                        header={'Announcement'}
                        icon={<Icon>arrow_drop_down</Icon>}
                        node="div"
                        style={{ margin: 0 }}
                    >
                        <Row style={{ margin: 0}}>
                            <CardPanel className="bg-warning z-depth-4" style={{ margin: 0}}>
                                <Row>
                                    <Col s={12}>
                                        <p>{props.settings?.banner_heading}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col s={12} style={bannerTextStyle}>
                                        <p>{props.settings?.banner_text || ""}</p>
                                    </Col>
                                </Row>
                                {
                                    props.settings?.banner_image &&
                                    <Row>
                                        <Col s={12}>
                                            <img src={props.settings?.banner_image} alt="Banner"
                                                style={Object.assign({width: "100%", height: "auto"},
                                                    cssStringToObj(props.settings?.banner_image_style))}/>
                                        </Col>
                                    </Row>
                                }
                            </CardPanel>
                        </Row>
                    </CollapsibleItem>
                </Collapsible>
            }
            {
                showProfileMsg && loggedInUser.is_yt_fields_filled === 1 ? <Col className = "profile-note-col">
                    <strong className = "profile-note">Note : </strong>
                    <span className = "profile-note-message">Please update your YouTube profile section. This will help us to create better content for your YouTube channel.   </span>
                </Col>
            : null
            }
            <div className="aside-right-top-bar">
                <div className="top-bar-left">
                    <div className="refresh-create-btn">
                        <Button icon={<Icon>refresh</Icon>} tooltip="Reload Cards" onClick={()=> refresh()}
                                    className="btn btn-primary" />
                        
                        {
                            (admin || customer) &&
                                <Button icon={<Icon>add</Icon>} onClick={showCreateCard}
                                        tooltip={requestsLimited ? `You have reached limit for video requests. Your limit will refresh on the next billing date. For further information contact your account manager.`
                                            : accountPending ? "We are setting up your account for file sharing, we will update you via email once it is done."
                                                : "Create New Video Request"} 
                                        className={"btn btn-primary" + ((requestsLimited || accountPending)
                                            ? "btn-secondary" : "btn-primary")}/> 
                        }
                    </div>
                </div>
                <div className="top-bar-center">
                <div className="top_bar">
                        <SearchBar cards = {props.cardList} setSearchText = {setSearchText} searchText = {searchText} searchCards ={searchCards} />
                    </div>
                </div>
                <div className="top-bar-right">
                    <TopHeader/>
                </div>
            </div>
            <div className="aside-top-filters">
            <Row className="hide-on-med-and-down difplay_flext">
                <Col className="date-filter-col">
                    <Modal
                        trigger={(
                            <Button node="button" className="date-btn" icon={<Icon left>date_range</Icon>}>
                                {toISODate(startDate)} ~ {toISODate(endDate)}
                            </Button>
                        )}
                        header="Add Date Range"
                        id="addDateRangeModal"
                        open={addDateRangeModalOpen}
                        style={{ height: '65%', width:'51%' }}
                        options={{
                            dismissible: true,
                            endingTop: '10%',
                            opacity: 0.5,
                            preventScrolling: true,
                            onCloseEnd: () => setAddDateRangeModalOpen(false)
                        }}
                    >
                        <DateRangePicker
                            ranges={[{
                                startDate: startDate ,
                                endDate : endDate ? endDate : new Date(),
                                key: 'selection',
                            }]}
                            direction="horizontal"
                            fixedHeight={true}
                            preventSnapRefocus={true}
                            months={2}
                            onChange={onDateChange} />
                            <Card className="due-date-card">
                                <Checkbox
                                    value="2"
                                    label="Due Data"
                                    checked={selectedDueDate}
                                    onChange ={() => setSelectedDueDate(!selectedDueDate)}
                                />
                            </Card>
                    </Modal>
                </Col>
                {
                    (admin || isTeamLead(loggedInUser)) &&
                    <Col className="view-input-col">
                        <Select className="z-depth-1 select-view-as"
                            value={viewAs}
                            onChange={setViewAs}
                            options={_.concat([{...loggedInUser, fullname: 'Me'}], 
                            props.users?.users?.filter(user => user.client_type === QA_USER || user.client_type === YT_HELPER ) || [], props.users?.customers || [])
                            .map(user => ({value: user.client_id, label: user.client_type === 'customer' ? `${user.fullname} (${user.client_type})` : `${getUserShortName(user.fullname)} (${user.client_type})`
                        }))
                            }
                            placeholder="View As"
                        />
                    </Col>
                }
                <Col>
                    <CardPanel className="toggle-panel">
                        <Checkbox value="1" label="Show Canceled Cards" checked={showCanceled} id="check-canceled"
                                  onChange={setShowCanceled.bind(this, Boolean(!showCanceled))}/>
                    </CardPanel>
                </Col>
                <Col>
                    <CardPanel className="toggle-panel">
                        <Checkbox value="2" label="Show Cards On Hold" checked={showHold} id="check-hold"
                                  onChange={setShowHold.bind(this, Boolean(!showHold))}/>
                    </CardPanel>
                </Col>
                <Col>
                    <CardPanel className="toggle-panel">
                        <Checkbox value="2" label="Show Cards In Repurpose" checked={showRepurposed} id="check-rp"
                                  onChange={setShowRepurposed.bind(this, Boolean(!showRepurposed))}/>
                    </CardPanel>
                </Col>
                <Col className="real-date">
                    <CardPanel style={{margin: 0, borderRadius: "8px", minWidth: '265px'}}>
                        <Icon left>access_time</Icon>
                        <Clock
                            format={"dddd, DD-MMM-YYYY | hh:mm:ss"}
                            ticking={true}
                            interval={1000}
                            timezone={process.env.REACT_APP_TIMEZONE}
                        />
                        <span> EST</span>
                    </CardPanel>
                </Col>
                {
                    customer && (props.cards.monthlyCardCount || props.cards.monthlyCardCount === 0) ? <Col>
                        <CardPanel className='month-card-created-cardpanel'>
                            <span>Monthly Card Created Count : </span>
                            <span style={{ margin: '0 4px 0px 4px' }}>{props.cards.monthlyCardCount} </span>
                        </CardPanel>
                    </Col>: null
                }
                <Col className="cutomer-filter-clear-btn">
                    <Button style={{ height: '44px' }} onClick={()=> clearFilter()}>Clear Filter {activeFilterCount() > 0 && `(+${activeFilterCount()})`}</Button>
                </Col>
            </Row>
            <Row className="hide-on-large-only hide-on-extra-large-only mobile-control-btns">
                <Col s={2} m={4}>
                    <Dropdown
                        trigger={<Button style={{backgroundColor: "#82b150", marginTop: "5px"}} floating large icon={<Icon>more_vert</Icon>}/>}
                        options={{
                            autoTrigger: false,
                            constrainWidth: false,
                            coverTrigger: false,
                            closeOnClick: false,
                        }}>
                        {React.Children.toArray([
                            (admin || customer) && !(requestsLimited || accountPending) ?
                            <Button icon={<Icon left>add</Icon>} flat onClick={showCreateCard} style={{margin: '5px'}}>
                                Create New Request</Button> : null,
                            accountPending ?
                                <p style={{margin: '20px'}}>Your DropBox video account is in progress, we will send you an email when we are done then you will be able create video request.</p>
                                : null,
                            requestsLimited ?
                                <p style={{margin: '20px'}}>You have reached limit for video requests. Your limit will refresh on the next billing date. For further information contact your account manager.</p>
                                : null,
                            <Button icon={<Icon left>refresh</Icon>} flat onClick={()=> refresh()} style={{margin: '5px'}}>Refresh</Button>,
                            <div style={{margin: '20px'}}>
                                <Checkbox value="1" label="Show Canceled Cards" checked={showCanceled} id="check-canceled"
                                          onChange={setShowCanceled.bind(this, Boolean(!showCanceled))}/>
                            </div>,
                            <div style={{margin: '20px'}}>
                                <Checkbox value="2" label="Show Cards On Hold" checked={showHold} id="check-hold"
                                          onChange={setShowHold.bind(this, Boolean(!showHold))}/>
                            </div>,
                            <div style={{margin: '20px'}}>
                                <Checkbox value="2" label="Show Cards In Repurpose" checked={showHold} id="check-repurp-2"
                                          onChange={setShowRepurposed.bind(this, Boolean(!showRepurposed))}/>
                            </div>,
                            (admin || isTeamLead(loggedInUser)) &&
                            <Select
                                value={viewAs}
                                onChange={setViewAs}
                                options={_.concat([{...loggedInUser, fullname: 'Me'}], 
                                props.users?.users?.filter(user => user.client_type === QA_USER || user.client_type === YT_HELPER) || [], props.users?.customers || [])
                                .map(user => ({value: user.client_id, label: user.client_type === 'customer' ? `${user.fullname} (${user.client_type})` : `${getUserShortName(user.fullname)} (${user.client_type})`
                            }))
                                }
                                placeholder="View As"
                            />,
                        ])}
                    </Dropdown>
                </Col>
                <Col s={10} m={8}>
                    <CardPanel style={{margin: 0, fontSize: "1.2rem"}}>
                        <Icon left>access_time</Icon>
                        <Clock
                            format={"DD-MMM-YYYY hh:mm:ss"}
                            ticking={true}
                            interval={1000}
                            timezone={process.env.REACT_APP_TIMEZONE}
                        />
                        <span> EST</span>
                    </CardPanel>
                </Col>
            </Row>
        </div>
       
            <Row className="row right-sec-row">
                <Col s={12} m={12}>
            {/* 4 columns --- */}
            <div className="card-rows">
                <Row className="video-rows" style={{ overflowX: "scroll", marginBottom: "0" }}>
                    {getCardListConfig({ showHold, showCanceled, showRepurposed }).map((item, index) => (
                        item.condition !== false && (
                            <Col className="mobile-card-rows" s={4} m={4} key={item.type + "-" + key + index}>
                                <Collapsible className={`${item.name.toLowerCase().replace(/\s+/g, '-')}-list`} accordion>
                                    <CardList
                                        name={item.name}
                                        viewCard={customer && item.type === "VIDEO_REQUEST" ? showEditCard : showCard}
                                        cards={props.cards?.cardList?.[item.key]}
                                        viewAs={viewAs?.value}
                                        searchText={searchText}
                                        startDate={startDate}
                                        endDate={endDate}
                                        selectedDueDate={selectedDueDate}
                                        type={item.type}
                                    />
                                </Collapsible>
                            </Col>
                        )
                    ))}
                </Row>
            </div>

            </Col>
            </Row>
            </Container>
    );
});

function mapStateToProps({cards, auth, users, settings}) {
    return {cards, auth, users, settings};
}