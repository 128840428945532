import React, { useState } from "react";
import { Button, Modal, Input, Spin, Select } from "antd";
import { errorToast } from "../../../api/Util";
import { generateAIResponse } from "../../../services/aiToolboxService";
import styles from "./AIToolboxModal.module.scss";
import "./AIToolboxModal.scss";
import {
  optionsAudienceType,
  optionsCategoryFor,
  optionsDurationFor,
} from "../../../constant/AIdata";
const AIToolboxModal = ({
  isModalOpen,
  childCallBack,
  selectedTool,
}) => {

  const formInitialValues = {
    title: "",
    audience: null,
    category: null,
    duration: null
  };

  const [additionalTextboxes, setAdditionalTextboxes] = useState(1);
  const [subTopicInputs, setSubTopicInputs] = useState(Array.from({ length: additionalTextboxes }, () => ""));
  const [toolboxStates, setToolboxStates] = useState({
    [selectedTool.title]: {
      formData: { ...formInitialValues },
      loading: false,
      isGenerateButtonDisabled: false,
    },
  });

  const currentToolboxState = toolboxStates[selectedTool.title] || {
    formData: formInitialValues,
    loading: false,
    isGenerateButtonDisabled: false,
  };

  const updateToolboxState = (updates) => {
    setToolboxStates((prev) => ({
      ...prev,
      [selectedTool.title]: {
        ...prev[selectedTool.title],
        ...updates,
      },
    }));
  };

  const handleInputChange = (value, name) => {
    updateToolboxState({
      formData: {
        ...currentToolboxState.formData,
        [name]: value,
      },
    });
  };

  const generateResponse = async () => {
    updateToolboxState({ loading: true, isGenerateButtonDisabled: true });
    const params = {
      title: currentToolboxState.formData.title,
      toolType: selectedTool.type,
      category: currentToolboxState.formData.category,
      audience_type: currentToolboxState.formData.audience,
      duration: currentToolboxState.formData.duration,
      sub_topics: subTopicInputs.filter((input) => input.trim() !== ""),
    };

    const response = await generateAIResponse(params);
    if (response.status || 1) {
      childCallBack("searched_data", selectedTool.type);

      childCallBack("close", "");
      updateToolboxState({ loading: false, isGenerateButtonDisabled: false });
      childCallBack("response_data", response?.data);
      localStorage.setItem('aiDataParams', JSON.stringify(params));
    } else {
      errorToast("Some Error Occured");
    }
  };


  const handleAddTextbox = () => {
    setAdditionalTextboxes((prev) => prev + 1);
    setSubTopicInputs((prev) => [...prev, ""]);
  };

  const handleRemoveTextbox = (indexToRemove) => {
    if (additionalTextboxes > 1) {
      setAdditionalTextboxes((prev) => prev - 1);
      setSubTopicInputs((prev) =>
        prev.filter((_, index) => index !== indexToRemove)
      );
    }
  };

  const handleTextInputChange = (index, value) => {
    setSubTopicInputs((prev) => {
      const newInputs = [...prev];
      newInputs[index] = value;
      return newInputs;
    });
  };
  const shouldRenderDurationSelect = selectedTool.title === "Video scripts";
  const generateButtonDisabled = currentToolboxState.isGenerateButtonDisabled || currentToolboxState.formData.title === "";

  return (
    <Modal
      title={selectedTool.title}
      open={isModalOpen}
      onCancel={() => childCallBack("close")}
      footer={[
        <Button
          key="back"
          className={`${styles.cancleButton} ${styles.AiToolboxModalBox}`}
          onClick={() => {
            updateToolboxState({ formData: formInitialValues });
            childCallBack("resetReprompt", selectedTool.type);
          }}
        >
          Reset
        </Button>,
        <Button
          key="submit"
          className={`${styles.generateButton} ${styles.AiToolboxModalBox} ${generateButtonDisabled ? 'generateButtonDisabled' : styles.AiToolboxModalBox}`}
          onClick={generateResponse}
          disabled={generateButtonDisabled}
        >
          Generate
        </Button>
      ]}
    >
      <div>
        <div className={"inputDiv"}>
          <Input.Search
            placeholder={selectedTool.placeholder}
            style={{
              width: '100%',
            }}
            name="title"
            className={"searchInput"}
            value={currentToolboxState.formData.title}
            onChange={(e) => handleInputChange(e.target.value, 'title')}
          />

          {/* <Select
            placeholder="Select Audience Type"
            className={"searchSelect"}
            value={currentToolboxState.formData.audience}
            name="audience"
            onChange={(value) => handleInputChange(value, 'audience')}
            options={optionsAudienceType.map((audience) => ({
              value: audience.value,
              label: audience.label,
            }))}
          /> */}
          {/* <Select
            placeholder="Select Category For"
            className={"searchSelect"}
            value={currentToolboxState.formData.category}
            name="category"
            onChange={(value) => handleInputChange(value, 'category')}
            options={optionsCategoryFor.map((category) => ({
              value: category.value,
              label: category.label,
            }))}
          /> */}

          {shouldRenderDurationSelect && (
            <Select
              placeholder="Select Duration For"
              className={"searchSelect"}
              value={currentToolboxState.formData.duration}
              name="duration"
              onChange={(value) => handleInputChange(value, 'duration')}
              options={optionsDurationFor.map((category) => ({
                value: category.value,
                label: category.label,
              }))}
            />
          )}
          {shouldRenderDurationSelect && (
            <>
              {subTopicInputs.map((text, index) => (
                <>
                  <Input.Search
                    placeholder={`Enter Sub Topic ${index + 1}`}
                    style={{ width: '88%' }}
                    className={"searchInput"}
                    name="sub_topics"
                    value={text}
                    key={index}
                    onChange={(e) => handleTextInputChange(index, e.target.value)}
                  />
                  {index === 0 && (
                    <Button
                      className={`${styles.addButton} ${styles.AiToolboxInputBox}`}
                      onClick={handleAddTextbox}
                      disabled={additionalTextboxes >= 4}
                      style={{ height: '44px' }}
                    >+</Button>
                  )}
                  {index > 0 && (
                    <Button
                      className={`${styles.crossButton} ${styles.AiToolboxInputBox}`}
                      onClick={() => handleRemoveTextbox(index)}
                    >-</Button>
                  )}
                </>
              ))}
            </>
          )}
        </div>
        <div style={{ textAlign: "center", marginTop: "22px" }}>
          {currentToolboxState.loading && <Spin />}
        </div>
      </div>
    </Modal>
  );
};

export default AIToolboxModal;
