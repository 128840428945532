import React, { useEffect, useState } from 'react';
import { Container, Table } from "react-materialize";
import styles from './BillingTable.module.scss';
import { isMobile } from '../../../api/Util';

export default ({ data }) => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    return (
        <Container id="customer-tabs-container" className={styles.container}>
            <Table className = "highlight">
                <thead>
                    <tr>
                        <th>{isMobile ? "B.No" : "Billing Number"}</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.length > 0 ? data.map((item, i)=>{
                            return (
                                <tr key={'billing-table-row'+i}>
                                    <td>{i+1}</td>
                                    <td>{item.start_date}</td>
                                    <td>{item.end_date}</td>
                                </tr>
                            )
                        }) : <tr>
                                <td colspan="6" style={{ textAlign: 'center' }}>No Data Found</td>
                            </tr>
                    }
                </tbody>
            </Table>
        </Container>
    );
}
